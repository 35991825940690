import React, { useEffect, useState } from "react";
import "./Wishlist.css";
import axios from "axios";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Wishlist = () => {

    const [countryCode, setCountryCode] = useState('');
    const [product, setProduct] = useState([]);
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();

    useEffect(() => {


        detectCountry();
    }, []);
    const detectCountry = async () => {
        try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            const detectedCountry = data.country.toUpperCase();
            setCountryCode(detectCountry)
            handleSubmit(detectedCountry)
        } catch (error) {
            console.error("Error detecting country:", error);
            setCountryCode("IN")
            handleSubmit('IN')
        }
    };
    const handleSubmit = async (detectedCountry) => {
        if (isAuthenticated) {
            try {
                const response = await axios.post('https://gravenautomation.com/admin/api/index', {
                    user_id: auth?.uid,
                    countrycode: detectedCountry
                }, {
                    headers: {
                        'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
                    }
                });

                setProduct(response.data.wishdata.data);
                console.log(response.data.wishdata.data);
            } catch (error) {
                console.log('error---->', error);
            }
        }
    };
    const RemoveFromWish = async (id, variant_id) => {
        try {
            const response = await axios.post('https://gravenautomation.com/admin/api/removefromwish', {
                user_id: auth?.uid,
                product_id: id,
                variant_id: variant_id
            }, {
                headers: {
                    'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
                }
            });
            console.log('response-->', response.data);

            if (response.data.status == 1) {
                Swal.fire('Success!', response.data.message, 'success');
                detectCountry();
            } else {
                Swal.fire('Oops...', response.data.message, 'error');
            }

        } catch (error) {
            console.log('error---->', error);
        }
    }

    return (
        <div className="wishlist-container">
            <h2>My Wishlist ({product?.length})</h2>
            {product?.length > 0 ? <div>
                {product.map(item => {
                    return (



                        <div className="wishlist-item">
                            <img
                                src={`https://gravenautomation.com/admin/public/product/images/${item.image}`} // Replace with actual product image path
                                alt="Xiaomi 14 CIVI"
                                className="product-image"
                            />
                            <div className="product-details"><Link className='wishbtn' to={`/${countryCode}/products/${item.variant_id}/${item.product_id}`} >
                                <h3>{item.name}</h3>
                            </Link>
                                {/* <div className="rating">
                                    <span>4.3 ⭐</span>
                                    <span>(1,096)</span>
                                    <span className="assured">Assured</span>
                                </div> */}
                                <div className="price-section">
                                    <span className="price">{item.currency}{' '}{item.selling_price}</span>
                                    {/* <span className="original-price">₹54,999</span> */}
                                    {/* <span className="discount">25% off</span> */}
                                </div>
                            </div>
                            <div  onClick={() => RemoveFromWish(item.product_id, item.variant_id)} className="delete-icon">🗑️</div>
                        </div>

                    )
                })}
            </div> :
                <div className="wishlist-container">
                    <div className="empty-wishlist">
                        <img
                            src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/mywishlist-empty_39f7a5.png" // Replace with actual image path
                            alt="Empty Wishlist"
                            className="wishlist-image"
                        />
                        <h2>Empty Wishlist</h2>
                        <p>You have no items in your wishlist. Start adding!</p>
                    </div>
                </div>}

        </div>
    );
};

export default Wishlist;
