import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';


const MyOrders = () => {
    const auth = useAuthUser();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [trackOpen, setTrackOpen] = useState(false);
    const [trackingDetails, setTrackingDetails] = useState(null);

    const [loading, setLoading] = useState(false);
    const [trackingLoading, setTrackingLoading] = useState(false);

    const handleGetData = async () => {
        const payload = {
            user_id: auth?.uid,
        };

        try {
            const response = await fetch('https://gravenautomation.com/admin/api/orderdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            console.log('result.data', result.data);

            setData(result.data);
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    };

    useEffect(() => {
        handleGetData();
    }, []);

    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedOrder(null);
    };

    const handleCancelOrder = async (orderId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const payload = { id: orderId };

                try {
                    const response = await fetch('https://gravenautomation.com/admin/api/cancelorder', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    const result = await response.json();

                    if (result.status) {
                        handleGetData();
                        Swal.fire('Canceled!', result.message, 'success');
                    } else {
                        Swal.fire('Failed', result.message, 'error');
                    }
                } catch (error) {
                    console.error('Error cancelling order:', error);
                    Swal.fire('Error', 'An error occurred while cancelling the order.', 'error');
                }
            }
        });
    };

    const AuthToken = async () => {
        try {
            const response = await fetch('https://gravenautomation.com/admin/api/getFedExToken');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.access_token;
        } catch (error) {
            console.error('Error fetching FedEx token:', error);
            throw error;
        }
    };

    const steps = ['Pending', 'Processing', 'On Delivery', 'Delivered', 'Declined', 'Completed'];
    // Track Order Functionality
    const handleTrackOrder = async (track_link) => {
        setTrackingLoading(true);
        const token = await AuthToken();
        // alert(token)
        // Example: Call an API to get tracking details
        // Replace 'yourFedExToken' with the actual token you obtained from the previous GET request.
        const fedExToken = token;
        const data =
        {
            trackingNumber: track_link,
            fedExToken: fedExToken
        }
        try {
            const response = await axios.post(
                'https://gravenautomation.com/admin/api/track',
                data,
                {
                    headers: {
                        // 'Authorization': `Bearer ${fedExToken}`,
                        'Content-Type': 'application/json',
                        // 'X-locale':'en_US'
                    },
                }
            );
            const trackingData = response.data;

            console.log(trackingData.output.completeTrackResults[0].trackResults[0].dateAndTimes.filter(item => item.type == 'SHIP'));



            setTrackingDetails({
                // status: trackingData.output.completeTrackResults[0].trackResults[0].latestStatusDetail.description,
                estimatedDelivery: trackingData.output.completeTrackResults[0].trackResults[0].scanEvents[0].date,
                trackingNumber: trackingData.output.completeTrackResults[0].trackResults[0].trackingNumberInfo.trackingNumber,
                statusByLocale: trackingData.output.completeTrackResults[0].trackResults[0].latestStatusDetail.statusByLocale,
                From: trackingData.output.completeTrackResults[0].trackResults[0].originLocation.locationContactAndAddress.address,
                TO: trackingData.output.completeTrackResults[0].trackResults[0].destinationLocation.locationContactAndAddress.address,
                SHIP: trackingData.output.completeTrackResults[0].trackResults[0].dateAndTimes.filter(item => item.type == 'SHIP'),
                // currentLocation: response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.scanLocation.city,
                // currentLocationstate: response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.scanLocation.stateOrProvinceCode,
                // currentLocationcountry: response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.scanLocation.countryName,
            });
            setTrackOpen(true);

        } catch (error) {
            console.log(error.response ? error.response : error.message);
        } finally {
            setTrackingLoading(false);  // Stop loading for tracking
            // setLoading(false);
        }


    };

    const handleCloseTrackModal = () => {
        setTrackOpen(false);
        setTrackingDetails(null);
    };
    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return 'default'; // Pending
            case 1:
                return 'primary'; // Processing
            case 2:
                return 'warning'; // On Delivery
            case 3:
                return 'success'; // Delivered
            case 4:
                return 'error'; // Declined
            default:
                return 'default'; // Completed or other
        }
    };

    return (
        <Container style={{ padding: 0 }}>
            <Typography variant="h4" gutterBottom align="center">
                My Orders ({data.length})
            </Typography>
            {trackingLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                    {/* Or use a loading GIF */}
                    <img width={50} src="https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif" alt="Loading..." />
                </Box>
            ) : (
                <List>
                    {data.map((order) => (
                        <React.Fragment key={order.id}>
                            <ListItem
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: { xs: 'center', sm: 'center' },
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        src={`https://gravenautomation.com/admin/public/product/images/${order.product_img}`}
                                        alt={order.product_name}
                                        sx={{ width: 80, height: 80, marginRight: 3, alignSelf: 'center' }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap sx={{ contain: 'inline-size' }}>
                                            {order.product_name}
                                        </Typography>
                                    }
                                    secondary={`Invoice: ${order.invoice_number} | Price: ₹${order.unit_price} | Quantity: ${order.quantity}`}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        alignItems: { xs: 'center', sm: 'flex-start' },
                                        marginTop: { xs: 2, sm: 0 },
                                        justifyContent: { xs: 'center', sm: 'flex-end' },
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#f43f5e',
                                            marginRight: { xs: 0, sm: '10px' },
                                            marginBottom: { xs: 2, sm: 0 },
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                            },
                                        }}
                                        onClick={() => handleOpenModal(order)}
                                    >
                                        View Details
                                    </Button>
                                    {order.track_link && <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#42a5f5',
                                            marginRight: { xs: 0, sm: '10px' },
                                            marginBottom: { xs: 2, sm: 0 },
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                            },
                                        }}
                                        onClick={() => handleTrackOrder(order.track_link)}
                                    >
                                        Track Order
                                    </Button>}
                                    {order.order_status == 4 ? (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'gray',
                                                width: {
                                                    xs: '100%',
                                                    sm: 'auto',
                                                },
                                            }}
                                        >
                                            Declined Order
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'gray',
                                                width: {
                                                    xs: '100%',
                                                    sm: 'auto',
                                                },
                                            }}
                                            onClick={() => handleCancelOrder(order.id)}
                                        >
                                            Cancel Order
                                        </Button>
                                    )}
                                </Box>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            )}

            {/* Order Details Modal */}
            {selectedOrder && (
                <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Order Details</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {selectedOrder.product_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    <strong>Invoice Number:</strong> {selectedOrder.invoice_number}
                                </Typography>
                                <Typography>
                                    <strong>Price:</strong> ₹{selectedOrder.unit_price}
                                </Typography>
                                <Typography>
                                    <strong>Delivery Status:</strong>{' '}
                                    {selectedOrder.order_status === 0
                                        ? 'Pending'
                                        : selectedOrder.order_status === 1
                                            ? 'Processing'
                                            : selectedOrder.order_status === 2
                                                ? 'On Delivery'
                                                : selectedOrder.order_status === 3
                                                    ? 'Delivered'
                                                    : selectedOrder.order_status === 4
                                                        ? 'Declined'
                                                        : 'Completed'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    <strong>Quantity:</strong> {selectedOrder.quantity}
                                </Typography>
                                <Typography>
                                    <strong>Order Date:</strong> {selectedOrder.created_at}
                                </Typography>
                                <Typography>
                                    <strong>Delivered Date:</strong> {selectedOrder.created_at}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Tracking Modal */}
            {/* {trackingDetails && (
                <Dialog open={trackOpen} onClose={handleCloseTrackModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Track Order</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="h6" gutterBottom>
                            Current Status: {trackingDetails.status}
                        </Typography>
                        <Typography gutterBottom>
                            Estimated Delivery: {trackingDetails.estimatedDelivery}
                        </Typography>
                        <Typography gutterBottom>
                            Current Location: {trackingDetails.currentLocation}
                        </Typography>
                        <Stepper activeStep={steps.indexOf(trackingDetails.status)} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTrackModal}>Close</Button>
                    </DialogActions>
                </Dialog>
            )} */}


            <Dialog open={trackOpen} onClose={handleCloseTrackModal} maxWidth="sm" fullWidth>
                <DialogTitle>Tracking Details</DialogTitle>
                <DialogContent>
                    {trackingDetails ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography><strong>Tracking ID:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{trackingDetails.trackingNumber}</Typography>
                            </Grid>

                            {trackingDetails.statusByLocale == 'Delivered' && <Grid item xs={6}>
                                <Typography><strong>Delivered:</strong></Typography>
                            </Grid>}
                            {trackingDetails.statusByLocale == 'Delivered' && <Grid item xs={6}>
                                {/* <Typography>{new Date(trackingDetails.estimatedDelivery).toLocaleString()}</Typography> */}
                                <Typography>{new Date(trackingDetails.estimatedDelivery).toDateString()}</Typography>
                            </Grid>
                            }
                            <Grid item xs={6}>
                                <Typography><strong>Delivery Status:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{trackingDetails.statusByLocale}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>From:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{`${trackingDetails.From.city}, ${trackingDetails.From.stateOrProvinceCode}, ${trackingDetails.From.countryName}`}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>To:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{`${trackingDetails.TO.city}, ${trackingDetails.TO.stateOrProvinceCode}, ${trackingDetails.TO.countryName}`}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>Ship Date:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{new Date(trackingDetails.SHIP[0].dateTime).toDateString()}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>Service:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{'FedEx International Priority'}</Typography>
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Typography><strong>Special Handling:</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{trackingDetails.specialHandling}</Typography>
                            </Grid> */}
                        </Grid>
                    ) : (
                        <Typography>Tracking information is not available.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTrackModal}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default MyOrders;
