import React from 'react';

import Banner from '../landing/Banner';

function Mission() {
 
  return (
    <div>
      <Banner heading={'Get in Touch'} pera={'Reach out to us for any inquiries or collaborations. We’re here to assist you.'} />

      <div className="container mt-5">
        <div className="row align-items-center">
          {/* Left Section */}
          <div className="col-md-12">
            <h2 className="mb-12">Our Core Principles</h2>
            <p>
            
Customer Centricity
At Graven Automation, we prioritize our customers above all else. Our
commitment to understanding their needs and delivering exceptional service is
at the core of everything we do. We believe that respect and appreciation for
our clients drive our success.
Collaborative Spirit
We recognize that our greatest achievements come from collaboration.
Success is a collective effort, and we foster a proactive and supportive
environment where every team member plays a vital role in reaching our
common goals.
Principled Integrity
Integrity is fundamental to our operations. We strive to do the right thing in all
our dealings, communicating openly and honestly within our team and with our
customers. We believe that trust is built through transparency and respect.
Ethical Commitment
Graven Automation is committed to conducting business ethically and
professionally. We adhere to the social and institutional frameworks of the
countries in which we operate, ensuring that our actions reflect our
commitment to responsible business practices.
Accountable Stewardship
We take ownership of our responsibilities and are determined to take the
necessary actions to achieve our objectives. Accountability is key to our
operations, and we consistently strive to meet and exceed our commitments.
Empowerment of People
Our people are our greatest asset. We actively promote the growth and
development of everyone who works with us, fostering a culture where
individual contributions lead to collective success. We believe that investing in
our team leads to a stronger company.
Pursuit of Excellence
We embrace a culture of continuous improvement, driven by humility and the
willingness to learn from our mistakes. Our goal is excellence in everything we
do, and we constantly seek opportunities to enhance our processes, products,
and services.
</p>
<h2 className="mb-12">Our Mission</h2>
            <p>
Graven Automation was founded with a clear mission: to provide fast, effective,
and reliable technical solutions to the industrial and automation sectors
worldwide. From the very beginning, we have focused on empowering
businesses to enhance their productivity, efficiency, and sustainability through
innovative automation technologies.
We are committed to building a future where industrial plants around the globe
can operate more efficiently and sustainably, driving growth while minimizing
environmental impact. Our work is fueled by passion and a dedication to
continuous innovation, strengthened by the development of our expertise.
At Graven Automation, we place great value on people—our employees, clients,
and communities. We believe in the power of diversity, seeing it as a resource
that drives creativity and excellence. Our long-term partnerships with the
countries and communities we operate in are a reflection of our commitment to
mutual growth and success.
            </p>
            
          </div>

          {/* Right Section (Form) */}
        
        </div>
      </div>
    </div>
  );
}

export default Mission;
