import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import { useEffect, useState } from "react";
import axios from "axios";
import Banner from "../landing/Banner";
import { useParams } from "react-router-dom";

function LandingCategorywise() {
  const [product, setProduct] = useState([]);

  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const { countryCode, categoryId } = useParams();

  // alert(categoryId)  

  const handleSubmit = async () => {
    try {
      const response = await axios.get(`https://gravenautomation.com/admin/api/Allproduct/${countryCode}/${categoryId}`, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log('response', response.data);

      setProduct(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleSubmit();
  }, [])

  // Filter products based on search query
  const filteredProducts = product?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log('filteredProducts--->', filteredProducts);

  return (
    <>
      <div style={{ backgroundColor: '#fff' }}>
        <ScrollToTopOnMount />
        <Banner
          heading={'Empowering Industry Innovations'}
          pera={'Leading the way in industrial automation solutions, delivering unparalleled excellence through top-tier products and exceptional service.'}
        />

        {/* Search Input */}


        <h2 className="text-center mt-4 mb-3">Product Category Wise</h2>
        <div className="container my-4" style={{ textAlign: '-webkit-center' }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
            style={{ width: '300px', alignSelf: 'center' }}
          />
        </div>
        <div className="container-fluid pb-5 px-lg-5">
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 px-md-5">
            {filteredProducts?.length > 0 ? (
              filteredProducts?.map((products, i) => (


                <FeatureProduct
                  key={i}
                  name={products.name}
                  rs={products.mrp}
                  image={products.image}
                  variant_id={products.variant_id}
                  id={products.id}
                  countryCode={countryCode}

                />

              ))
            ) : (
              <div className="text-center">No products found</div>
            )}
          </div>
        </div>


      </div>
    </>
  );
}

export default LandingCategorywise;
