import Image from "../../nillkin-case-1.jpg";
import { Link } from "react-router-dom";

function RelatedProduct(props) {

  const price = 10000;
  let percentOff;
  let offPrice = `${price}Ks`;

  if (props.percentOff && props.percentOff > 0) {
    percentOff = (
      <div
        className="badge bg-dim py-2 text-white position-absolute"
        style={{ top: "0.5rem", right: "0.5rem" }}
      >
        {props.percentOff}% OFF
      </div>
    );

    offPrice = (
      <>
        <del>{price}Ks</del> {price - (props.percentOff * price) / 100}Ks
      </>
    );
  }
console.log('props.countryCode',props.countryCode);

  return (
    
    <Link
    to={`/${props.countryCode}/products/${props.data?.variant_id}/${props.data?.id}`}
    //  to={{
    //   pathname: `/${props.countryCode}/products/${props.data?.variant_id}`, // URL path
    //   state: { productId: props.data?.id, variant_id: props.data?.variant_id,countryCode:props.countryCode }
    // }}
      className="col text-decoration-none"
      // href="!#"
      replace
    >
      <div style={{width:'auto'}} className="card shadow-sm">
        {/* {percentOff} */}
        <img
          className="card-img-top cover"
          height="200"
          alt=""
          src={`https://gravenautomation.com/admin/public/product/images/${props.data?.image}`}
        />
        <div className="card-body">
          <h5 className="card-title text-center text-dark text-truncate">
            {props.data?.name}
          </h5>
          <p className="card-text text-center text-muted">{props.data?.mrp}</p>
        </div>
      </div>
    </Link>
  );
}

export default RelatedProduct;
