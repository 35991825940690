import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio, Divider, Avatar, List, ListItem, ListItemText, ListItemIcon, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GiftCardIcon from '@mui/icons-material/CardGiftcard';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import Icon from '@mui/material/Icon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/system';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useHistory } from 'react-router-dom';
import MyCart from '../mycart/MyCart';
import MyOrders from '../MyOrders/MyOrders';
import EnquiryList from '../Enquiry/EnquiryList';
import axios from 'axios';
import Swal from 'sweetalert2';
import Wishlist from '../wishlist/Wishlist';

const ProfileInfo = ({
  firstName,
  setFirstName,
  email,
  setEmail,
  mobile,
  setMobile,
  companyname,
  setCompanyname,
  designation,
  setDesignation,
  location,
  setLocation,
  industrytype,
  setIndustrytype,
  companysize,
  setCompanysize,
  preferredproduct,
  setPreferredproduct,
  frequency_of_purchase,
  setFrequency_of_purchase,
  average_order_value,
  setAverage_order_value,
  preferred_brands,
  setPreferred_brands,
  describe_you_need,
  setDescribe_you_need,
  technical_specification,
  setTechnical_specification,
  communication_preference,
  setCommunication_preference,
  best_time_to_contact,
  setBest_time_to_contact,
  interested_in_products_demos_workshops,
  setInterested_in_products_demos_workshops,
  fedback_comments,
  setFedback_comments,
  isMobile,
  onUpdate
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const handleEditToggle = () => {
    setIsEditable((prev) => !prev);
  };

  return (
    <Box>
      {/* Personal Information Section */}
      <Typography variant="h5" mb={2}>
        Personal Information
        <Button startIcon={<EditIcon />} onClick={handleEditToggle}>
          {isEditable ? "Cancel" : "Edit"}
        </Button>
      </Typography>
      <Box mb={2} display="flex" gap={2} flexDirection={isMobile ? 'column' : 'row'}>
        <TextField
          label="Full Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          disabled={!isEditable}
        />
        <TextField
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          disabled={!isEditable}
        />
      </Box>

      <Box mb={2} display="flex" gap={2} flexDirection={isMobile ? 'column' : 'row'}>
        <TextField
          label="Mobile Number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          fullWidth
          disabled={!isEditable}
        />
        <TextField
          label="Company Name (Number of employees, annual turnover)"
          // placeholder="e.g., Manufacturing, Automation"  
          fullWidth
          value={companyname}
          onChange={(e) => setCompanyname(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2} display="flex" gap={2} flexDirection={isMobile ? 'column' : 'row'}>
        <TextField
          label="Designation"
          // placeholder="e.g., Manufacturing, Automation"
          fullWidth
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          disabled={!isEditable}
        />
        <TextField
          label="Location"
          // placeholder="e.g., Manufacturing, Automation"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          disabled={!isEditable}
        />
      </Box>

      {/* Industry Details Section */}
      <Typography variant="h5" mt={4} mb={2}>
        Industry Details
      </Typography>
      <Box mb={2}>
        <TextField
          label="Industry Type"
          placeholder="e.g., Manufacturing, Automation"
          fullWidth
          value={industrytype}
          onChange={(e) => setIndustrytype(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Company Size"
          placeholder="e.g., Number of employees or annual turnover"
          fullWidth
          value={companysize}
          onChange={(e) => setCompanysize(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Preferred Products"
          placeholder="e.g., PLCs, VFDs, HMIs, motors"
          fullWidth
          value={preferredproduct}
          onChange={(e) => setPreferredproduct(e.target.value)}
          disabled={!isEditable}
        />
      </Box>

      {/* Purchase Preferences Section */}
      <Typography variant="h5" mt={4} mb={2}>
        Purchase Preferences
      </Typography>
      <Box mb={2}>
        <TextField
          label="Frequency of Purchase"
          placeholder="e.g., Weekly, Monthly, Quarterly"
          fullWidth
          value={frequency_of_purchase}
          onChange={(e) => setFrequency_of_purchase(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Average Order Value"
          placeholder="e.g., INR 50,000"
          fullWidth
          value={average_order_value}
          onChange={(e) => setAverage_order_value(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Preferred Brands"
          placeholder="e.g., Siemens, Schneider Electric"
          fullWidth
          value={preferred_brands}
          onChange={(e) => setPreferred_brands(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Typography variant="h5" mt={4} mb={2}>
        Custom Requirements
      </Typography>
      <Box mb={2}>
        <TextField
          label="Describe Your Needs"
          fullWidth
          value={describe_you_need}
          onChange={(e) => setDescribe_you_need(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Technical Specifications"
          fullWidth
          value={technical_specification}
          onChange={(e) => setTechnical_specification(e.target.value)}
          disabled={!isEditable}
        />
      </Box>

      {/* Communication Preferences Section */}
      <Typography variant="h5" mt={4} mb={2}>
        Communication Preferences
      </Typography>
      <RadioGroup row
        value={communication_preference}
        onChange={(event) => setCommunication_preference(event.target.value)} >
        <FormControlLabel
          control={<Radio disabled={!isEditable} />}
          label="Email"
          value="email"
        />
        <FormControlLabel
          control={<Radio disabled={!isEditable} />}
          label="Phone"
          value="phone"
        />
        <FormControlLabel
          control={<Radio disabled={!isEditable} />}
          label="WhatsApp"
          value="whatsapp"
        />
      </RadioGroup>
      <Box mb={2}>
        <TextField
        style={{marginTop: 20,}}
          label="Best Time to Contact"
          fullWidth
          value={best_time_to_contact}
          onChange={(e) => setBest_time_to_contact(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      <Typography variant="h5" mt={4} mb={2}>
        Feedback & Interests
      </Typography>
      <Typography variant="h6" mt={4} mb={2}>
        Interested in Product Demos/Workshops
      </Typography>
      <RadioGroup row value={interested_in_products_demos_workshops}
        onChange={(event) => setInterested_in_products_demos_workshops(event.target.value)}>
        <FormControlLabel
          control={<Radio disabled={!isEditable} />}
          label="Yes"
          value="yes"
        />
        <FormControlLabel
          control={<Radio disabled={!isEditable} />}
          label="No"
          value="no"
        />
      </RadioGroup>
      <Box mb={2}>
        <TextField
        style={{marginTop: 20,}}
          label="Feedback/Comments"
          fullWidth
          value={fedback_comments}
          onChange={(e) => setFedback_comments(e.target.value)}
          disabled={!isEditable}
        />
      </Box>
      {isEditable && (
        <Box mt={4}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#f43f5e' }}
            onClick={() => {
              onUpdate();
              setIsEditable(false); // Exit edit mode after update
            }}
          >
            Update Profile
          </Button>
        </Box>
      )}
    </Box>
  );
};



const Sidebar = styled(Box)(({ theme }) => ({
  width: '250px',
  padding: '20px',
  borderRight: '1px solid #ccc',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '10px',
    borderRight: 'none',
    borderBottom: '1px solid #ccc',
  },
}));

const ProfileContent = styled(Box)({
  padding: '20px',
  flex: 1,
});

const Profile = () => {
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [gender, setGender] = useState('');
  const [companyname, setCompanyname] = useState('');
  const [designation, setDesignation] = useState('');
  const [location, setLocation] = useState('');
  const [industrytype, setIndustrytype] = useState('');
  const [companysize, setCompanysize] = useState('');
  const [preferredproduct, setPreferredproduct] = useState('');
  const [frequency_of_purchase, setFrequency_of_purchase] = useState('');
  const [average_order_value, setAverage_order_value] = useState('');
  const [preferred_brands, setPreferred_brands] = useState('');
  const [describe_you_need, setDescribe_you_need] = useState('');
  const [technical_specification, setTechnical_specification] = useState('');
  const [communication_preference, setCommunication_preference] = useState('');
  const [best_time_to_contact, setBest_time_to_contact] = useState('');
  const [interested_in_products_demos_workshops, setInterested_in_products_demos_workshops] = useState('');
  const [fedback_comments, setFedback_comments] = useState('');
  const [selectedTab, setSelectedTab] = useState('profile');

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const detectedCountry = data.country.toUpperCase();
        if (!isAuthenticated) {
          history.push(`/${detectedCountry}/login`);
        }
      } catch (error) {
        console.error("Error detecting country:", error);
      }
    };
    detectCountry();
  }, [isAuthenticated, history]);

  const isMobile = useMediaQuery('(max-width:900px)');

  const handleProfileUpdate = async () => {
    try {
      const response = await axios.post('https://gravenautomation.com/admin/api/updateProfile', {
        user_id: auth?.uid,
        name: firstName,
        email,
        phone: mobile,
        companyname,
        designation,
        location,
        industrytype,
        companysize,
        preferredproduct,
        frequency_of_purchase,
        average_order_value,
        preferred_brands,
        describe_you_need,
        technical_specification,
        communication_preference,
        best_time_to_contact,
        interested_in_products_demos_workshops,
        fedback_comments,

        // Add other fields as necessary
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log('Profile updated successfully:', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Profile Updated!',
        text: 'Your profile has been updated successfully.',
        confirmButtonText: 'Okay'
      });
      handleSubmit();
      // Optionally, you can display a success message or update the state
    } catch (error) {
      console.log('Error updating profile:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://gravenautomation.com/admin/api/UserProfile', {
        user_id: auth?.uid
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      setFirstName(response.data.user.name);
      setEmail(response.data.user.email);
      setMobile(response.data.user.phone);
      setCompanyname(response.data.user.companyname);
      setDesignation(response.data.user.designation);
      setLocation(response.data.user.location);
      setIndustrytype(response.data.user.industrytype);
      setCompanysize(response.data.user.companysize);
      setPreferredproduct(response.data.user.preferredproduct);
      setFrequency_of_purchase(response.data.user.frequency_of_purchase);
      setAverage_order_value(response.data.user.average_order_value);
      setPreferred_brands(response.data.user.preferred_brands);
      setDescribe_you_need(response.data.user.describe_you_need);
      setTechnical_specification(response.data.user.technical_specification);
      setCommunication_preference(response.data.user.communication_preference);
      setBest_time_to_contact(response.data.user.best_time_to_contact);
      setInterested_in_products_demos_workshops(response.data.user.interested_in_products_demos_workshops);
      setFedback_comments(response.data.user.fedback_comments);
    } catch (error) {
      console.log('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} style={{ paddingTop: isMobile ? '25%' : '8%', backgroundColor: '#fff', paddingBottom: '5%', width: '100%' }}>
      {/* Sidebar */}
      <Sidebar>
        <Box textAlign="center" mb={4}>
          <Avatar sx={{ width: 56, height: 56, margin: '0 auto' }}> {auth?.name[0]}</Avatar>
          <Typography variant="h6" mt={2}>
            {auth?.name}
          </Typography>
        </Box>
        <List>
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('profile')}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile Information" />
          </ListItem>
          <Divider />
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('orders')}>
            <ListItemIcon>
              <OrderIcon />
            </ListItemIcon>
            <ListItemText primary="My Orders" />
          </ListItem>
          <Divider />
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('enquiry')}>
            <ListItemIcon>
              <OrderIcon />
            </ListItemIcon>
            <ListItemText primary="Enquiry List" />
          </ListItem>
          <Divider />
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('wishlist')}>
            <ListItemIcon>
              <OrderIcon />
            </ListItemIcon>
            <ListItemText primary="My WishList" />
          </ListItem>
        </List>
      </Sidebar>

      {/* Profile Content */}
      <ProfileContent>
        {/* Render content based on the selected tab */}
        {selectedTab === 'profile' && (
          <ProfileInfo
            firstName={firstName}
            setFirstName={setFirstName}
            email={email}
            setEmail={setEmail}
            mobile={mobile}
            setMobile={setMobile}
            gender={gender}
            setGender={setGender}
            companyname={companyname}
            setCompanyname={setCompanyname}
            designation={designation}
            setDesignation={setDesignation}
            location={location}
            setLocation={setLocation}
            industrytype={industrytype}
            setIndustrytype={setIndustrytype}
            companysize={companysize}
            setCompanysize={setCompanysize}
            preferredproduct={preferredproduct}
            setPreferredproduct={setPreferredproduct}
            frequency_of_purchase={frequency_of_purchase}
            setFrequency_of_purchase={setFrequency_of_purchase}
            average_order_value={average_order_value}
            setAverage_order_value={setAverage_order_value}
            preferred_brands={preferred_brands}
            setPreferred_brands={setPreferred_brands}
            describe_you_need={describe_you_need}
            setDescribe_you_need={setDescribe_you_need}
            technical_specification={technical_specification}
            setTechnical_specification={setTechnical_specification}
            communication_preference={communication_preference}
            setCommunication_preference={setCommunication_preference}
            best_time_to_contact={best_time_to_contact}
            setBest_time_to_contact={setBest_time_to_contact}
            interested_in_products_demos_workshops={interested_in_products_demos_workshops}
            setInterested_in_products_demos_workshops={setInterested_in_products_demos_workshops}
            fedback_comments={fedback_comments}
            setFedback_comments={setFedback_comments}
            isMobile={isMobile}
            onUpdate={handleProfileUpdate}  // Pass the update handler
          />
        )}
        {selectedTab === 'orders' && <MyOrders />}
        {selectedTab === 'enquiry' && <EnquiryList />}
        {selectedTab === 'wishlist' && <Wishlist />}
      </ProfileContent>
    </Box>
  );
};

export default Profile;
