import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'; // For social icons
import Banner from '../landing/Banner';


function Cookie_Policy() {

    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        // Fetch HTML data from your API
        fetch('https://gravenautomation.com/admin/api/pages')
            .then((response) => response.json())
            .then((data) => {
                // Assuming your API returns HTML in a field called 'content'
                console.log('data==>',data);
                
                setHtmlContent(data.data[8].text);
            })
            .catch((error) => console.error('Error fetching HTML data:', error));
    }, []);
    return (
        <div>
            <Banner heading={'Leading Automation Solutions'} pera={'Empowering industries through innovative automation'} />
            {htmlContent ? (
                <div style={{padding:20}}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            ) : (
                <p>Loading...</p>
            )}
        </div >
    );
}

export default Cookie_Policy;
