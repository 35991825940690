import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, Button, IconButton, Box, Container, Divider, CardMedia, Paper, Dialog, DialogTitle, DialogContent, DialogActions, TextField, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMediaQuery } from '@mui/material';
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useParams, useHistory } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";


const MyCart = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const { country } = useParams();
  const [cart, setCart] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');
  const auth = useAuthUser();
  console.log('auth?.uid',auth?.uid);
  
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/${country}/index`);

    }
  }, []);
  // Address form state
  const [address, setAddress] = useState({
    name: '',
    phone: '',
    email: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    addressLine1: ''
  });

  const { error, isLoading, Razorpay } = useRazorpay();

  // Payment method state
  const [paymentMethod, setPaymentMethod] = useState('cash'); // Default to Cash on Delivery

  // Additional state for dynamic city and state based on pincode
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handlePincodeChange = (event) => {
    const value = event.target.value;

    if (value.length <= 6) {
      setAddress({ ...address, pincode: value });

      if (value.length === 6) {
        fetch(`https://api.postalpincode.in/pincode/${value}`)
          .then((response) => response.json())
          .then((data) => {
            if (data[0].Status === "Success") {
              const { State, District,Country } = data[0].PostOffice[0];
                console.log(data[0].PostOffice[0])
              setAddress((prevAddress) => ({
                ...prevAddress,
                country: Country,
                state: State,
                city: District,
              }));
            } else {
              console.error("Invalid pincode");
            }
          })
          .catch((error) => {
            console.error("Error fetching state and city:", error);
          });
      }
    } else {
      console.error("Pincode should be 6 digits");
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://gravenautomation.com/admin/api/index', {
        user_id: auth?.uid,
        countrycode: country
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      setCart(response.data.cartdata.data);
      console.log(response.data.cartdata.data);
      
    } catch (error) {
      console.log(error);
    }
  };

  const AddQTY = async (data) => {
    try {
      await axios.post('https://gravenautomation.com/admin/api/addqtytocart', {
        user_id: auth?.uid,
        variant_id: data.variant_id,
        product_id: data.product_id,
        countrycode:country
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      handleSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveQTY = async (data) => {
    try {
      await axios.post('https://gravenautomation.com/admin/api/removeqtycart', {
        user_id: auth?.uid,
        variant_id: data.variant_id,
        product_id: data.product_id
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      handleSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveItem = async (data) => {
    try {
      await axios.post('https://gravenautomation.com/admin/api/removefromcart', {
        user_id: auth?.uid,
        variant_id: data.variant_id,
        product_id: data.product_id
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      handleSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const increaseQuantity = (data) => {
    setCart(cart.map(item =>
      item.id === data.id ? { ...item, quantity: item.quantity + 1 } : item
    ));
    AddQTY(data);
  };

  const decreaseQuantity = (data) => {
    setCart(cart.map(item =>
      item.id === data.id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
    ));
    RemoveQTY(data);
  };

  const removeItem = (data) => {
    setCart(cart.filter(item => item.id !== data.id));
    RemoveItem(data);
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.unit_price * item.quantity, 0);
  };

  const getTotalDiscount = () => {
    return cart.reduce((total, item) => total + item.discount * item.quantity, 0);
  };

  const handleCheck = async () => {
    if (paymentMethod == 'online') {
      handlePayment()
    } else {
      handlePaymentCash()
    }
  }
  const handlePayment = () => {

    const options = {
      key: 'rzp_test_nvxeVlH43xyZaT',
      amount: getTotalPrice() * 100,
      currency: cart[0]?.currencycode,
      name: 'Test Company',
      description: 'Test Transaction',
      handler: (response) => {
        console.log(response);
        //   alert('Payment Successful!');
        handlePaymentOnline();

      },
      prefill: {
        name: address.name,
        email: address.email,
        contact: address.phone,
      },
      theme: {
        color: '#F37254',
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();

  };

  const handlePaymentCash = async () => {

    const payload = {
      user_id: auth?.uid,
      shipping_address: {
        billing_name: address.name,
        billing_phone: address.phone,
        billing_email: address.email,
        billing_country: address.country,
        billing_state: address.state,
        billing_city: address.city,
        billing_area: "",
        billing_pincode: address.pincode,
        billing_address: address.addressLine1,
        billing_address_type: null
      },
      billing_address: {
        billing_name: address.name,
        billing_phone: address.phone,
        billing_email: address.email,
        billing_country: address.country,
        billing_state: address.state,
        billing_city: address.city,
        billing_area: "",
        billing_pincode: address.pincode,
        billing_address: address.addressLine1,
        billing_address_type: null
      },
      razorpay_order_id: "order_jhsagmsa"
    };
    console.log(payload);

    try {
      const response = await fetch('https://gravenautomation.com/admin/api/checkoutcart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
     
      if (result.message == 'Order successful') {
        console.log('Order placed successfully:', result);
        Swal.fire({
          title: 'Order Successful!',
          text: 'Your order has been placed successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Optionally redirect or perform other actions after the alert
          history.push(`/${country}/Index`);
        });
      }
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue placing your order. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handlePaymentOnline = async () => {

    const payload = {
      user_id: auth?.uid,
      shipping_address: {
        billing_name: address.name,
        billing_phone: address.phone,
        billing_email: address.email,
        billing_country: country,
        billing_state: address.state,
        billing_city: address.city,
        billing_area: "",
        billing_pincode: address.pincode,
        billing_address: address.addressLine1,
        billing_address_type: null
      },
      billing_address: {
        billing_name: address.name,
        billing_phone: address.phone,
        billing_email: address.email,
        billing_country: country,
        billing_state: address.state,
        billing_city: address.city,
        billing_area: "",
        billing_pincode: address.pincode,
        billing_address: address.addressLine1,
        billing_address_type: null
      },
      razorpay_order_id: "order_jhsagmsa"
    };
    console.log(payload);

    try {
      const response = await fetch('https://gravenautomation.com/admin/api/checkoutcartonline', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log('Order placed successfully:', result);
      if (result.message == 'Order successful') {
        Swal.fire({
          title: 'Order Successful!',
          text: 'Your order has been placed successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Optionally redirect or perform other actions after the alert
          history.push(`/${country}/Index`);
        });
      }
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue placing your order. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <Container sx={{ marginTop: 15 }}>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
        <Typography variant="h5" color="dark" gutterBottom>
          Graven Automation
        </Typography>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">Deliver to: {address.name + ',' + address.phone + ',' + address.addressLine1 + ',' + address.city + ',' + address.state}</Typography>
          <Button onClick={handleClickOpen} variant="outlined" size="small">Add Address</Button>
        </Grid>
      </Paper>

      {cart.length === 0 ? (
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', marginTop: 2 }}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Your Cart is Empty
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            It looks like you haven't added anything to your cart yet. Start shopping now!
          </Typography>
          <Button variant="contained" color="primary" href="/">
            Go to Shop
          </Button>
        </Paper>
      ) : (
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              {cart.map(item => (
                <Grid container key={item.id} alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <CardMedia
                      component="img"
                      image={`https://gravenautomation.com/admin/public/product/images/${item.image}`}
                      alt={item.name}
                      sx={{ borderRadius: 2 }}
                    />
                  </Grid>
                  <Grid item xs={9} container direction="column">
                    <Typography variant="subtitle1" gutterBottom>
                      {item.name}
                    </Typography>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: 1 }}>
                      <Box>
                        <Typography variant="h6" color="text.primary" component="span">
                        {item?.currencysymbol} {item?.unit_price}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton size="small" onClick={() => decreaseQuantity(item)}>
                          <RemoveIcon />
                        </IconButton>
                        <Typography variant="body2" component="span" sx={{ padding: '0 8px' }}>
                          {item.quantity}
                        </Typography>
                        <IconButton size="small" onClick={() => increaseQuantity(item)}>
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid container justifyContent="flex-end" sx={{ marginTop: 1 }}>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => removeItem(item)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6">Order Summary</Typography>
              <Divider sx={{ marginY: 1 }} />
              <Typography variant="body1">Total Price: {cart[0]?.currencysymbol} {getTotalPrice()}</Typography>
              {/* <Typography variant="body1">Total Discount: ₹{getTotalDiscount()}</Typography> */}
              <Divider sx={{ marginY: 1 }} />
              <Typography variant="h6">Total Amount: ₹{getTotalPrice()}</Typography>
              <Button variant="contained" style={{ backgroundColor: '#f43f5e', marginTop: 20 }} onClick={() => address.addressLine1 && address.city && address.email && address.name && address.phone && address.state && address.pincode != '' ? handleCheck() : handleClickOpen()}>
                Checkout
              </Button>
            </Paper>
          </Grid>
        </Grid>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Address</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={address.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone"
            name="phone"
            value={address.phone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={address.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Pincode"
            name="pincode"
            value={address.pincode}
            onChange={handlePincodeChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Country"
            name="country"
            value={address.country}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="State"
            name="state"
            value={address.state}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="City"
            name="city"
            value={address.city}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            disabled
          />

          <TextField
            label="Address Line 1"
            name="addressLine1"
            value={address.addressLine1}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          {/* Payment Method Selection */}
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>Select Payment Method:</Typography>
          <RadioGroup
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <FormControlLabel value="online" control={<Radio />} label="Online Payment" />
            <FormControlLabel value="cash" control={<Radio />} label="Cash on Delivery" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} color="primary">Save Address</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyCart;
