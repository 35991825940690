import React, { useEffect } from 'react';
import './Blog.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Banner from '../landing/Banner';

const blogPosts = [
  {
    id: 1,
    title: "How Automation is Transforming the Industry",
    date: "September 5, 2024",
    image: "https://gravenautomation.com/admin/wp-content/uploads/2024/05/cropped-GROVEN-removebg-preview-300x300.png",
    excerpt: "Automation has drastically improved efficiency in various industries. Learn how these advancements are shaping the future.",
  },
  {
    id: 2,
    title: "Top 5 Automation Tools for 2024",
    date: "August 25, 2024",
    image: "https://gravenautomation.com/admin/wp-content/uploads/2024/05/cropped-GROVEN-removebg-preview-300x300.png",
    excerpt: "Here are the top automation tools you need to know about in 2024. These tools are revolutionizing the way businesses operate.",
  },
  {
    id: 3,
    title: "The Future of AI and Automation",
    date: "August 10, 2024",
    image: "https://gravenautomation.com/admin/wp-content/uploads/2024/05/cropped-GROVEN-removebg-preview-300x300.png",
    excerpt: "AI is the brain behind automation. Explore how AI and automation will coexist to build a more efficient future.",
  },
];

function Blog() {

    useEffect(() => {
        AOS.init({
          once: false,
        //   disable: "phone",
          duration: 1000,
          easing: "ease-out-cubic",
        });
      }, []);
    
  return (
    <div>
         <Banner heading={'Blogs'} pera={'Our Blogs.'} />
    
    <div  style={{marginTop:'0%',marginBottom:'5%'}} className="blog-container">
    
      <header className="blog-header">
        <h1>Latest Blog Posts</h1>
        <p>Discover the latest trends and insights in automation.</p>
      </header>

      <div className="blog-grid">
        {blogPosts.map((post) => (
          <div   data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000" key={post.id} className="blog-card">
            <img  src={post.image} alt={post.title}  className="blog-image" />
            <div className="blog-content">
              <h2 className="blog-title">{post.title}</h2>
              <p className="blog-date">{post.date}</p>
              <p className="blog-excerpt">{post.excerpt}</p>
              {/* <a href={`/blog/${post.id}`} className="read-more">
                Read More →
              </a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Blog;
