// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import { useMediaQuery } from "@mui/material";
// import "./Banner.css";
// import axios from "axios";

// function BannerImage(props) {
//   const isMobile = useMediaQuery("(max-width:650px)");
//   return (
//     <div>
//       <div
//         className="ratio"
//         style={{

//           position: "relative",
//         }}
//       >
//         {/* Image itself */}
//         <video className="d-block w-100 h-100 bg-dark cover" autoPlay muted loop playsInline >
//           <source src="https://wideoblog.wpenginepowered.com/wp-content/uploads/2024/04/video-automation.mp4" type="video/mp4" />
//         </video>
//         {/* <img
//           className="d-block w-100 h-100 bg-dark cover"
//           alt=""
//           src={props.image}
//         /> */}

//         {/* Transparent black overlay */}
//         <div
//           className="overlay"
//           style={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.7)", // 50% transparency
//             zIndex: 1,
//           }}
//         ></div>

//         {/* Text at the bottom over the overlay */}
//         <div
//           className="carousel-caption"
//           style={{
//             position: "absolute",
//             bottom: "20px", // Positioned near the bottom
//             left: 0,
//             width: "100%", // Full width
//             zIndex: 2, // Ensures it's on top of the overlay
//             color: "#fff", // White text for contrast
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center", // Horizontally centered
//             justifyContent: "center", // Vertically aligned
//             textAlign: "center",
//           }}
//         >
//           {isMobile ? (
//             <h4 style={{ color: "#fff" }}>{props.heading}</h4>
//           ) : (
//             <h1 style={{ color: "#fff" }}>{props.heading}</h1>
//           )}
//           {isMobile ? (
//             <h4 style={{ color: "#fff", fontSize: 12 }}>{props.pera}</h4>
//           ) : (
//             <p style={{ color: "#fff" }}>{props.pera}</p>
//           )}

//         </div>
//       </div>
//     </div>
//   );
// }

// function Banner({ heading, pera }) {
//   const isMobile = useMediaQuery("(max-width:750px)");

//   const settings = {
//     dots: isMobile ? false : true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true, // Enables auto-slide
//     autoplaySpeed: 5000, // 5 seconds for each slide
//     arrows: false, // Disable next/previous arrows (can be enabled if needed)
//   };

//   const [product, setProduct] = useState([]);
//   const [countryCode, setCountryCode] = useState('');


//   useEffect(() => {
//     const detectCountry = async () => {
//       try {
//         const response = await fetch('https://ipinfo.io/json');
//         const data = await response.json();
//         const detectedCountry = data.country.toUpperCase();
//         setCountryCode(detectedCountry);
//         handleSubmit(detectedCountry);
//       } catch (error) {
//         console.error("Error detecting country:", error);
//         setCountryCode('IN');
//       }
//     };

//     detectCountry();
//   }, []);

//   const handleSubmit = async (detectedCountry) => {
//     try {
//       const response = await axios.post('https://gravenautomation.com/admin/api/index', {
//         user_id: "",
//         countrycode: detectedCountry
//       }, {
//         headers: {
//           'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
//         }
//       });
//       console.log(response.data.slider);

//       setProduct(response.data.slider);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div style={{ marginTop: isMobile ? "70px" : "80px" }}>
//       <Slider {...settings}>
//         {product.map(item => {
//           console.log('item.banner_img', item.banner_img);

//           return (
//             <BannerImage
//               heading={heading}
//               pera={pera}
//               image={`https://gravenautomation.com/admin/public/images/sliders/${item.banner_img}`
//               }
//             />
//           )
//         })}
       

//         {/* <source src="https://videos.pexels.com/video-files/2865276/2865276-uhd_2560_1440_30fps.mp4" type="video/mp4"/> */}

//       </Slider>
//     </div>
//   );
// }

// export default Banner;


import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import "./Banner.css";
import axios from "axios";

function BannerImage(props) {
  const isMobile = useMediaQuery("(max-width:650px)");
  return (
    <div>
      <div
        className="ratio"
        style={{
          position: "relative",
        }}
      >
        {/* Video itself */}
        <video
          className="d-block w-100 h-100 bg-dark cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source
            src="https://media.istockphoto.com/id/1208258468/video/industry-4-0-modern-factory-project-engineer-talks-to-female-operator-who-controls-facility.mp4?s=mp4-640x640-is&k=20&c=HT9AJYkYXF1hD8ogUKDyTiba1AhMq-G4pxImSHVzL7s="
            type="video/mp4"
          />
        </video>

        {/* Transparent black overlay */}
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // 70% transparency
            zIndex: 1,
          }}
        ></div>

        {/* Text at the bottom over the overlay */}
        <div
          className="carousel-caption"
          style={{
            position: "absolute",
            bottom: "20px", // Positioned near the bottom
            left: 0,
            width: "100%", // Full width
            zIndex: 2, // Ensures it's on top of the overlay
            color: "#fff", // White text for contrast
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Horizontally centered
            justifyContent: "center", // Vertically aligned
            textAlign: "center",
          }}
        >
          {isMobile ? (
            <h4 style={{ color: "#fff" }}>{props.heading}</h4>
          ) : (
            <h1 style={{ color: "#fff" }}>{props.heading}</h1>
          )}
          {isMobile ? (
            <h4 style={{ color: "#fff", fontSize: 12 }}>{props.pera}</h4>
          ) : (
            <p style={{ color: "#fff" }}>{props.pera}</p>
          )}
        </div>
      </div>
    </div>
  );
}

function Banner({ heading, pera }) {
  const isMobile = useMediaQuery("(max-width:750px)");
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch("https://ipinfo.io/json");
        const data = await response.json();
        const detectedCountry = data.country.toUpperCase();
        setCountryCode(detectedCountry);
        handleSubmit(detectedCountry);
      } catch (error) {
        console.error("Error detecting country:", error);
        setCountryCode("IN");
      }
    };

    detectCountry();
  }, []);

  const handleSubmit = async (detectedCountry) => {
    try {
      await axios.post(
        "https://gravenautomation.com/admin/api/index",
        {
          user_id: "",
          countrycode: detectedCountry,
        },
        {
          headers: {
            "X-CSRF-Token": "C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: isMobile ? "20px" : "20px" }}>
      {/* Display the single video banner */}
      <BannerImage
        heading={heading}
        pera={pera}
        image="https://wideoblog.wpenginepowered.com/wp-content/uploads/2024/04/video-automation.mp4"
      />
    </div>
  );
}

export default Banner;

