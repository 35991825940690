import React, { useEffect, useState } from 'react';
import './Index.css'; // Custom CSS for card styling and animations

import Slider from "react-slick";


const Coursal = ({data}) => {
    console.log('data==>',data);
    
    const [slidesToShow, setSlidesToShow] = useState(4); // Default setting

    // Media query change handling
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setSlidesToShow(1);  // Show 1 slide for small screens
            } else if (window.innerWidth < 900) {
                setSlidesToShow(2);  // Show 2 slides for medium screens
            } else if (window.innerWidth < 1200) {
                setSlidesToShow(3);  // Show 2 slides for medium screens
            } else {
                setSlidesToShow(4) // Show 3 slides for large screens
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,         // Enable centering
        // centerPadding: '50px',    // Padding around the center slide
    };


    const cards = [
        {
            title: 'Card 1',
            description: 'This is the first card.',
            image: 'https://images.squarespace-cdn.com/content/v1/5e6b8563380ccc4e4fd26a4f/1691669501638-1UBNU057FWULHSWIVM7V/kongsberggruppen.jpeg',
        },
        {
            title: 'Card 2',
            description: 'This is the second card.',
            image: 'https://industrial.omron.eu/images/video_default_thumbnail.jpg',
        },
        {
            title: 'Card 3',
            description: 'This is the third card.',
            image: 'https://mma.prnewswire.com/media/75250/delta_air_lines_logo.jpg?p=facebook',
        },
        {
            title: 'Card 1',
            description: 'This is the first card.',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa02dzQk6So5JxnWDbhd1NC99XVHQ0_l7-Ow&s',
        },
        {
            title: 'Card 2',
            description: 'This is the second card.',
            image: 'https://industrialelectricalco.com/wp-content/uploads/2021/12/Yaskawa-logo-JPG.png',
        },
        {
            title: 'Card 3',
            description: 'This is the third card.',
            image: 'https://img.etimg.com/thumb/width-1600,height-900,imgsize-47656,resizemode-75,msid-100094495/industry/renewables/japans-mitsubishi-electric-to-build-india-plant-for-231-million.jpg',
        },
        {
            title: 'Card 3',
            description: 'This is the third card.',
            image: 'https://www.tvh.com/sites/tvh/files/styles/max_650x650/public/2023-08/Schneider-Electric-logo-jpg-.png?itok=pxXk-20m',
        },
        {
            title: 'Card 3',
            description: 'This is the third card.',
            image: 'https://www.canadianelectricalwholesaler.ca/wp-content/uploads/2023/05/abb.siemens_CEW_836.jpg',
        },
        {
            title: 'Card 3',
            description: 'This is the third card.',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuERs6T5fyxzh2MeEgf83OIqONEfmADznHLQ&s',
        },
    ];

    return (
        <div style={{ backgroundColor: '#fff', marginTop: 50, marginBottom: 0 }}>
            <div style={{ overflow: 'hidden', backgroundColor: '#fff', paddingBottom: 0, marginTop: 10 }}>
                <h2 style={{ textAlign: 'center', marginTop: 30, fontSize: 25 }}>Brands We Deal In</h2>
                <Slider {...settings}>

                    {/* {cards.map((card, index) => (
                <Card
                    key={index}
                    title={card.title}
                    description={card.description}
                    image={card.image}
                />
            ))} */}
                    {data.map((card, index) => (
                        <div>
                            <img style={{ marginLeft: 10, marginRight: 10, width: 200, height: 200, objectFit: 'contain' }} src={`https://gravenautomation.com/admin/public/product/photo_brandswedeal/${card.photo_brandswedeal}`} alt={'title'} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Coursal;
