import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Typography,
    Divider,
    Button,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const EnquiryList = () => {
    const auth = useAuthUser();
    const [data, setData] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleGetData = async () => {
        const payload = {
            user_id: auth?.uid,
        };

        try {
            const response = await fetch('https://gravenautomation.com/admin/api/productenquirylist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            console.log('result--->', result);
            setData(result.data);
        } catch (error) {
            console.error('Error fetching enquiry data:', error);
        }
    };

    useEffect(() => {
        handleGetData();
    }, []);

    const getStatusLabel = (status) => {
        return status ? 'Resolved' : 'Pending';
    };

    const getStatusStyles = (status) => {
        return {
            backgroundColor: status ? '#4CAF50' : '#f44336',
            color: '#fff',
            padding: '4px 12px',
            borderRadius: '12px',
            fontWeight: 'bold',
            fontSize: isMobile ?'7px':'12px',
            textAlign: 'center',
            minWidth: '80px',
            marginTop: isMobile ? '8px' : '0',
        };
    };

    const handleDownloadPdf = (pdfUrl) => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = pdfUrl.split('/').pop(); // Extract the file name from the URL
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.warn('No PDF URL available for download.');
        }
    };

    return (
        <Container>
            <Typography
                variant={isMobile ? "h6" : "h5"}
                gutterBottom
                align="center"
                style={{ fontWeight: 600 }}
            >
                My Enquiries ({data?.length})
            </Typography>
            <List>
                {data?.map((enquiry) => (
                    <React.Fragment key={enquiry.id}>
                        <ListItem>
                            <Grid container spacing={2} alignItems="center">
                                {/* Avatar and Image */}
                                <Grid item xs={isMobile ? 2 : 2} container justifyContent="flex-start">
                                    <ListItemAvatar>
                                        
                                            <Avatar
                                                variant="square"
                                                src={`https://gravenautomation.com/admin/public/product/images/${enquiry.product_img}`}
                                                alt={enquiry.product_name}
                                                sx={{
                                                    width: isMobile ? 30 : 80,
                                                    height: isMobile ? 30 : 80,
                                                    marginRight: isMobile ? 0 : 3,
                                                }}
                                            />
                                        
                                    </ListItemAvatar>
                                </Grid>

                                {/* Product Info */}
                                <Grid item xs={isMobile ? 5 : 5}>
                                    <ListItemText
                                        primary={
                                            <Typography noWrap variant="body1" style={{ fontWeight: 500 }}>
                                                {enquiry.name}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{ marginBottom: 4 }}
                                                >
                                                    Message: {enquiry.message}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {/* {new Date(enquiry.created_at).toLocaleDateString()} */}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </Grid>

                                {/* Download Button */}

                                <Grid item xs={isMobile ? 2 : 2} container justifyContent="center">
                                    {enquiry.pdf && (
                                        <img
                                            src="https://cdn-icons-png.flaticon.com/128/201/201153.png"
                                            alt="PDF Icon"
                                            style={{
                                                width: isMobile ? 20 : 40,
                                                height: isMobile ? 20 : 40,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleDownloadPdf(enquiry.pdf)}
                                        />
                                    )}
                                </Grid>
                                {/* Status Badge */}
                                <Grid item xs={isMobile ? 3 : 3} container justifyContent={isMobile ? 'center' : 'flex-end'}>
                                    <Typography style={getStatusStyles(enquiry.status)}>
                                        {getStatusLabel(enquiry.status)} {enquiry.status === 0 ? '' : enquiry.discountmessage ? 'with ' + enquiry.discountmessage : 'with No Discount'}
                                    </Typography>
                                </Grid> 

                            </Grid>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Container>
    );
};

export default EnquiryList;
